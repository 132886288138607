import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Categories } from '../components/pages/categories'
import { useSiteMetadata } from '../hooks/useSiteMetadata'

const CategoriesPage = (props: any) => {
  const siteMetadata = useSiteMetadata()
  const en = props.location.pathname.split('/')[1] === 'en'

  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { language: { eq: "ja" } } }) {
        group(field: frontmatter___category) {
          fieldValue
        }
      }
    }
  `)

  return <Categories {...props} en={en} siteMetadata={siteMetadata} data={allMarkdownRemark} />
}

export default CategoriesPage
